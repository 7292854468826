import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import PostMeta from "../components/postmeta"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle} post={post}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <PostMeta post={post} />

      <article
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(32),
          padding: `${rhythm(0)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>
          <h1
            style={{
              marginTop: rhythm(2),
              marginBottom: rhythm(2),
            }}
          >
            {post.frontmatter.title}
          </h1>
          <Img
            style={{
              maxWidth: "500px",
              marginTop: rhythm(2),
              marginBottom: rhythm(2),
              marginRight: "auto",
              marginLeft: "auto",
            }}
            fluid={post.frontmatter.featured.top.fluid}
          />
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer></footer>
      </article>

      <nav
        style={{
          flex: "0 0 100%",
        }}
      >
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD MM YYYY")
        description
        category
        featured {
          top: childImageSharp {
            fluid(maxWidth: 800, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      timeToRead
      wordCount {
        words
      }
    }
  }
`
