import React from "react"

import PostMetaCSS from "./postmeta.module.css"

const PostMeta = ({ post }) => {
  return (
    <>
      <table className={PostMetaCSS.table}>
        <tbody>
          <tr>
            <td>date:</td>
            <td>{post.frontmatter.date}</td>
          </tr>
          <tr>
            <td>category:</td>
            <td>{post.frontmatter.category}</td>
          </tr>
          <tr>
            <td>time to read:</td>
            <td>{post.timeToRead} min</td>
          </tr>
          <tr>
            <td>word count:</td>
            <td>{post.wordCount.words}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default PostMeta
